class SpaceDay {
  constructor() {
    const japanStandardTime = new Date().toLocaleString("ja-JP", {
      timeZone: "Asia/Tokyo",
    });
    this.now = new Date(japanStandardTime);
    this.days = ["日", "月", "火", "水", "木", "金", "土"];
  }
  /**
   *
   * @param {*} d
   * @returns
   */
  today(d = this.now) {
    return d;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  nextDate(d = this.now) {
    const next = new Date(d);
    next.setDate(next.getDate() + 1);
    return next;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  previousDate(d = this.now) {
    const pre = new Date(d);
    pre.setDate(pre.getDate() - 1);
    return pre;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  convertToObject(d) {
    const today = {
      date: d.getDate(),
      day: this.days[d.getDay()],
      month: d.getMonth() + 1,
      year: d.getFullYear(),
    };
    return today;
  }

  convertToDate(d, { zeroPadding = false, delimiter = "/" } = {}) {
    const month = d.getMonth() + 1;
    const date = d.getDate();

    if (zeroPadding) {
      return `${d.getFullYear()}${delimiter}${("0" + month).slice(
        -2
      )}${delimiter}${("0" + date).slice(-2)}`;
    }

    return `${d.getFullYear()}${delimiter}${month}${delimiter}${date}`;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  nextWeek(d, count = 1) {
    const next = new Date(d);
    next.setDate(next.getDate() + 7 * count);
    return next;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  previousWeek(d, count = 1) {
    const next = new Date(d);
    next.setDate(next.getDate() - 7 * count);
    return next;
  }

  /**
   *
   * @param {*} date1
   * @param {*} date2
   * @returns
   */
  compareTwoDates(date1, date2) {
    return (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  /**
   *
   * @param {*} date1
   * @param {*} date2
   * @returns
   */
  differenceTwoDates(d1, d2) {
    const date1 = new Date(
      `${d1.getFullYear()}/${d1.getMonth() + 1}/${d1.getDate()}`
    );
    const date2 = new Date(
      `${d2.getFullYear()}/${d2.getMonth() + 1}/${d2.getDate()}`
    );
    return (date2 - date1) / 86400000;
  }

  /**
   *
   * @param {*} d
   * @returns
   */
  formatDateToString(d) {
    return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
  }

  /**
   *
   * @param {*} targetDate
   * @param {*} dateFrom
   * @param {*} dateTo
   * @returns
   */
  betweenDates(targetDate, dateFrom, dateTo) {
    return (
      dateFrom.getTime() - targetDate.getTime() <= 0 &&
      dateTo.getTime() - targetDate.getTime() >= 0
    );
  }
}

export default new SpaceDay();
