const favoriteUrl = `/api/favorites.json`;

// お気に入り取得
const getFavorite = (spaceId) => {
  return $.ajax({
    url: favoriteUrl,
    type: "GET",
    dataType: "text",
    data: { space_id: spaceId },
  });
};

// お気に入り登録
const postFavorite = (spaceId) => {
  return $.ajax({
    url: favoriteUrl,
    type: "POST",
    dataType: "json",
    data: { space_id: spaceId },
  });
};

// お気に入り解除
const deleteFavorite = (spaceId) => {
  return $.ajax({
    url: favoriteUrl,
    type: "DELETE",
    dataType: "json",
    data: { space_id: spaceId },
  });
};

const favoriteButtonAction = async (ev) => {
  const spaceId = $(ev.currentTarget).data("spaceId");

  if ($(ev.currentTarget).hasClass("favorite")) {
    $(ev.currentTarget).removeClass("favorite");
    const deleteResult = await deleteFavorite(spaceId);
    if (deleteResult.errors) {
      // UX優先でAPI結果を待たずに先にボタン状態を変化させているため、エラー時は戻す
      $(ev.currentTarget).addClass("favorite");
    }
  } else {
    $(ev.currentTarget).addClass("favorite");
    const postResult = await postFavorite(spaceId);
    if (postResult.errors) {
      // UX優先でAPI結果を待たずに先にボタン状態を変化させているため、エラー時は戻す
      $(ev.currentTarget).removeClass("favorite");
    }
  }
};

$(function () {
  // スペース詳細画面
  const $spaceDetail = $(".js-spaceDetail");
  const $favoriteButton = $($spaceDetail).find(
    ".spacedetail-img--favorite-button"
  );

  // スペース詳細画面 ボタンクリック
  $($favoriteButton).on("click", (ev) => {
    const isLoggedIn = $(ev.currentTarget).data("isLoggedIn");
    const redirectUrl = $(ev.currentTarget).data("redirectUrl");
    if (isLoggedIn) {
      favoriteButtonAction(ev);
    } else {
      location.href = redirectUrl;
    }
  });
});

$(function () {
  // スペース一覧画面
  const $rentalSpaceItems = $(".rentalspace-item");
  for (let i = 0; i < $rentalSpaceItems.length; i++) {
    const $favoriteButton = $($rentalSpaceItems[i]).find(
      ".rentalspace-item--favorite-button"
    );
    $($favoriteButton).on("click", (ev) => {
      const isLoggedIn = $(ev.currentTarget).data("isLoggedIn");
      const redirectUrl = $(ev.currentTarget).data("redirectUrl");

      ev.stopPropagation();
      ev.preventDefault();

      if (isLoggedIn) {
        favoriteButtonAction(ev);
      } else {
        location.href = redirectUrl;
      }
    });
  }
});

// ブラウザバック時のリロード処理
$(window).off("popstate.backward");
$(() => {
  history.replaceState(
    null,
    document.getElementsByTagName("title")[0].innerHTML,
    null
  );
  $(window).on("popstate.backward", (ev) => {
    window.location.reload();
  });
});
