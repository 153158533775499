import app from "../config";

$(function () {
  getReviewLists();
});

function getReviewLists(target) {
  const elm = $("#review-list");
  const spaceId = elm.data("spaceId");

  var pageNum = $(target) ? $(target).data("page") : 1;

  if (elm.length == 0 || spaceId == undefined || pageNum == undefined) {
    return;
  }

  $.ajax({
    url: `${app.API_URI}/api/space_reviews.json?id=${spaceId}&page=${pageNum}`,
    type: "GET",
    dataType: "json",
  })
    .done(function (data) {
      var data_stringify = JSON.stringify(data);
      var data_json = JSON.parse(data_stringify);
      var reviewItems = data_json.data;

      if (reviewItems.length > 0) {
        // レビュー一覧描画
        var listHtml = "";
        reviewItems.forEach((item) => {
          var date = new Date(item.started_at);

          // レビュー星数classを定義
          if (item.rating == 0) {
            var ratingClass = [
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          } else if (0 < item.rating && item.rating <= 0.5) {
            var ratingClass = [
              "rate-half",
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          } else if (0.5 < item.rating && item.rating <= 1) {
            var ratingClass = [
              "rate-one",
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          } else if (1 < item.rating && item.rating <= 1.5) {
            var ratingClass = [
              "rate-one",
              "rate-half",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          } else if (1.5 < item.rating && item.rating <= 2) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          } else if (2 < item.rating && item.rating <= 2.5) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-half",
              "rate-zero",
              "rate-zero",
            ];
          } else if (2.5 < item.rating && item.rating <= 3) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-zero",
              "rate-zero",
            ];
          } else if (3 < item.rating && item.rating <= 3.5) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-half",
              "rate-zero",
            ];
          } else if (3.5 < item.rating && item.rating <= 4) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-zero",
            ];
          } else if (4 < item.rating && item.rating <= 4.5) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-half",
            ];
          } else if (4.5 < item.rating && item.rating <= 5) {
            var ratingClass = [
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-one",
              "rate-one",
            ];
          } else {
            var ratingClass = [
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
              "rate-zero",
            ];
          }

          listHtml += `
          <div class="review-item">
            <div class="review-item__detail">
              <h4 class="review-item--title">${item.title}</h4>
              <p class="review-item--time">
              ${date.getFullYear()}年${
            date.getMonth() + 1
          }月${date.getDate()}日 利用
              </p>
              <span class="review-average--rating-view inline-flex">
              <span class="review-average--rating-star">
                <svg viewBox="0 0 24 24" class="${ratingClass[0]}">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                  <path d="M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27V2z"></path>
                  </svg>
                <svg viewBox="0 0 24 24" class="${ratingClass[1]}">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                  <path d="M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27V2z"></path>
                  </svg>
                <svg viewBox="0 0 24 24" class="${ratingClass[2]}">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                    <path d="M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27V2z"></path>
                    </svg>
                <svg viewBox="0 0 24 24" class="${ratingClass[3]}">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                  <path d="M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27V2z"></path>
                </svg>
                <svg viewBox="0 0 24 24" class="${ratingClass[4]}">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                  <path d="M12 2L9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27V2z"></path>
                </svg>
              </span>
              <span class="review-average--rating-num">${item.rating}</span>
            </span>
              <div class="review-item--text ${
                item.body.length > 86 ? "overflow-hidden" : ""
              }">
                <p>${item.body}</p>
                <button type=”button” type="button" class="link-text js-full-text">...すべて読む</button>
              </div>
            </div>
            <div class="review-item__reply" ${
              item.reply == null ? "hidden" : ""
            }>
              <p class="review-item--title">スペース掲載者からの返信</p>
              <p class="review-item--text">${item.reply}</p>
            </div>
          </div>
        `;
        });

        // ページャー描画
        var pagerNumHtml = "";
        for (var i = 1; i <= data_json.total_page; i++) {
          if (i == data_json.current_page) {
            var html = `
            <li>${i}</li>
            <!--<span class="page">-->
            <!--</span>-->
            `;
          } else if (
            i == 1 ||
            i == data_json.total_page ||
            (data_json.current_page == 1 && i <= 3) ||
            (data_json.current_page == data_json.total_page &&
              data_json.total_page - 3 < i) ||
            i == data_json.current_page - 1 ||
            i == data_json.current_page + 1
          ) {
            var html = `
            <li>
              <button type=”button” class="js-pager" data-page="${i}">${i}</button>
            </li>
            <!--<span class="page">-->
            <!--</span>-->
            `;
          } else if (
            ((i !== 1 || i !== data_json.total_page) &&
              (i == data_json.current_page - 2 ||
                i == data_json.current_page + 2)) ||
            (data_json.current_page == 1 && i == data_json.current_page + 3) ||
            (data_json.current_page == data_json.total_page &&
              i == data_json.current_page - 3)
          ) {
            var html = `
            <li>…</li>
            <!--<span class="page">-->
            <!--</span>-->
            `;
          } else {
            var html = ``;
          }
          pagerNumHtml += html;
        }
        var pagerHtml = "";
        pagerHtml = `
          <ul class="pager-list">
            <li ${data_json.current_page - 1 == 0 ? "hidden" : ""}>
              <button type=”button” class="previous js-pager" data-page=${
                data_json.current_page - 1
              }> </button>
            </li>
            ${pagerNumHtml}
            <li ${
              data_json.current_page == data_json.total_page ? "hidden" : ""
            }>
              <button type=”button” class="next js-pager" data-page=${
                data_json.current_page + 1
              }> </button>
            </li>
          </ul>
        `;

        $("#review-list").html(listHtml);
        $("#review-pagination").html(pagerHtml);

        // clickイベント定義
        clickCallBack();
      }
    })
    .fail(function (data) {
      console.log("error");
    });
}

function clickCallBack() {
  // ページャー処理
  $(".js-pager").on("click", function () {
    getReviewLists(this);
    $("body,html").animate(
      {
        scrollTop: $("#review-list").offset().top,
      },
      400,
      "swing"
    );
  });

  // レビュー全文表示
  $(".js-full-text").on("click", function () {
    $(this).hide().parent().removeClass("overflow-hidden");
  });
}
