// header smoooth scroll
$(function () {
  $(document).on("click", "a[href^='#']", function (event) {
    event.preventDefault();

    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top - 70,
      },
      500
    );
  });
});

// scrollに応じてheaderの色を変更
$(function () {
  const header = document.getElementById("detailHeader");
  const boxes = document.querySelectorAll(".js-observeIndex");

  const options = {
    root: null,
    rootMargin: "0px 0px -90% 0px",
    threshold: 0,
  };

  const observer = new IntersectionObserver(function (entries) {
    entries.forEach((entry) => {
      // 要素Aと要素Bが重なる場合
      if (entry.isIntersecting) {
        document.querySelectorAll(".headerActiveItem").forEach((item) => {
          item.classList.remove("headerActiveItem");
        });
        document
          .getElementById(`headerItem-${entry.target.id}`)
          .classList.add("headerActiveItem");

        const el = document.getElementById("detailHeader");
        if (entry.target.id === "detail") {
          el.scrollTo(50, 0);
        } else if (entry.target.id === "review") {
          el.scrollTo(25, 0);
        } else if (entry.target.id === "space") {
          el.scrollTo(0, 0);
        }
      }
    });
  }, options);

  boxes.forEach((box) => {
    observer.observe(box);
  });
});
