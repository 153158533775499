$(document).on("turbolinks:load", function () {
  $("#js-booking-submit").on("click", function () {
    // spinnerを表示する
    $(".js-space-booking-loading").removeClass("hidden");
    $("#js-reserve-booking-form").submit();
  });

  $("#go-to-reserve").on("click", function () {
    // spinnerを表示する
    $(".js-space-booking-loading").removeClass("hidden");
  });
});
