const app = {
  development: {
    API_URI: "https://dev.fitspace.co.jp",
  },
  staging: {
    API_URI: "https://staging.fitspace.co.jp",
  },
  production: {
    API_URI: "https://fitspace.co.jp",
  },
};
const env = process.env.NODE_ENV || "development";
export default app[env];
