$(function () {
  document
    .querySelectorAll(".js-switchTab button[data-tabs-target]")
    .forEach((button) => {
      button.addEventListener("click", (e) => {
        document
          .querySelectorAll(".js-switchTab button[data-tabs-target]")
          .forEach((button) => {
            button.classList.remove("switchTabActiveItem");
          });
        e.currentTarget.classList.add("switchTabActiveItem");
      });
    });
});
