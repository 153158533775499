import { Toast } from "flowbite-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { HiCheck, HiExclamation, HiX } from "react-icons/hi";

export type ToastType = "success" | "failure" | "notice";

const TIME_PASSED_REMOVE_TOAST = 6000;

export const showSuccessToast = ({ message }: { message: string }) => {
  const toast = document.getElementById("toast");
  const root = createRoot(toast!);
  root.render(<RenderToast message={message} type="success" />);

  setTimeout(() => {
    root.unmount();
  }, TIME_PASSED_REMOVE_TOAST);
};

export const showFailureToast = ({ message }: { message: string }) => {
  const toast = document.getElementById("toast");
  const root = createRoot(toast!);
  root.render(<RenderToast message={message} type="failure" />);

  setTimeout(() => {
    root.unmount();
  }, TIME_PASSED_REMOVE_TOAST);
};

const RenderToast = ({
  type,
  message,
}: {
  type: ToastType;
  message: string;
}) => {
  const SuccessToast = ({ message: string }) => (
    <Toast>
      <div className="inline-flex h-8 w-16 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
        <HiCheck className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <Toast.Toggle />
    </Toast>
  );

  const FailureToast = ({ message: string }) => (
    <Toast>
      <div className="inline-flex h-8 w-16 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
        <HiX className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <Toast.Toggle />
    </Toast>
  );

  const NoticeToast = ({ message: string }) => (
    <Toast>
      <div className="inline-flex h-8 w-16 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
        <HiExclamation className="h-5 w-5" />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <Toast.Toggle />
    </Toast>
  );

  if (type == "success") {
    return <SuccessToast message={message} />;
  } else if (type == "failure") {
    return <FailureToast message={message} />;
  } else if (type == "notice") {
    return <NoticeToast message={message} />;
  }
};
