import { TIMES, TIMES_15, TIME_UNIT, TIME_UNIT_HAFT } from "../config/constant";
import SpaceDay from "../utils/day";
import { formatDate } from "../utils/format_date";
import { findIndex, resolveEndTime, resolveStartTime } from "../utils/utils";

class BookingView {
  constructor() {
    this.times = TIMES;
    this.times15 = TIMES_15;
    this.dayParentBox = document.querySelector(".day-box");

    // スペースオーナーの空き状況表示枠
    this.bookingAvailableBox = document.querySelector(".booking-status-box");
  }

  /**
   * @description 各日付に応じるDay objectをhtmlに伝えて、htmlを生成する
   * @param {
   *      day: string,
   *      date: string,
   *      month: string,
   *      year: string,
   * } dayObj
   */
  renderDaysOfWeek(dayObj) {
    return `
        <div class="day-item ${
          dayObj.day === "土"
            ? "text-blue"
            : dayObj.day === "日"
            ? "text-red"
            : ""
        }">
          <span class="font-bold">
            ${dayObj.month === 0 ? "12" : dayObj.month}/${dayObj.date}
          </span>
          <span class="-mt-0.5">${dayObj.day}</span>
        </div>
        `;
  }

  /**
   *
   * @param {*} markup
   */
  applyDayOfWeekMarkup(markup) {
    this.dayParentBox = document.querySelector(".day-box");

    this.dayParentBox.innerHTML = "";
    this.dayParentBox.insertAdjacentHTML("afterbegin", markup);
  }

  /**
     * @description 各日付に応じる 空き状況 をhtmlに伝えて、htmlを生成する
     * @param {
     *  start_at: string 09:00,
     *  end_at: string 22:00

     * } availableObj
     * @param {*} day
     */
  renderBookingAvailable(availableObj, day) {
    // RESOLVE TIME
    const startTime = resolveStartTime(availableObj.start_at);
    const endTime = resolveEndTime(availableObj.end_at);

    var markup = "";
    const startIndex = findIndex(this.times15, startTime);
    const endIndex = findIndex(this.times15, endTime);

    if (startIndex < endIndex) {
      for (var i = startIndex; i < endIndex; i++) {
        const start =
          this.times15[i].split(":")[1] % 2 !== 0
            ? this.times15[i - 1]
            : this.times15[i];
        const availabilityMarkup = `
                <div data-start="${start}" data-begin="${
          this.times15[i]
        }" data-end="${this.times15[i + 1]}" class="booking-item--availability">
                </div>
                `;
        markup += availabilityMarkup;
      }
    }

    for (var i = 0; i < this.times15.length; i++) {
      const notAvailable = `
                <div data-begin="${this.times15[i]}" data-end="${
        this.times15[i + 1]
      }" class="none booking-item--availability">
                </div>
            `;
      markup += notAvailable;
    }

    return `
            <div class="booking-item-wrapper">
                <div data-date="${day}" class="booking-item booking-item--1">
                    ${markup}
                </div>
            </div>
        `;
  }

  /**
   *
   * @param {*} markup
   */
  applyAvailableBookingMarkup(markup) {
    this.bookingAvailableBox = document.querySelector(".booking-status-box");

    this.bookingAvailableBox.innerHTML = "";
    this.bookingAvailableBox.insertAdjacentHTML("afterbegin", markup);
  }

  /**
   * @description 00:00から25:59までタイミングラインを表示する
   */
  renderTimeLine() {
    const timeBox = document.querySelector(".time-box");
    var ul = document.createElement("div");
    var mark = "";

    for (var time of this.times) {
      var timeItem = `
            <div class="min-h-[35px] Text-Body3 text-secondary text-center">
                ${time}
            </div>
            `;
      mark += timeItem;
    }

    ul.innerHTML = mark;
    if (timeBox) {
      timeBox.innerHTML = "";
      timeBox.insertAdjacentElement("afterbegin", ul);
    }
  }

  renderMyBooking(data) {
    data.ended_at = resolveEndTime(this.convertDurationToEnded(data));

    if (!data.uid) {
      return `
            <div data-begin="${data.started_at}" data-end="${data.ended_at}" class="selected-booking booking-item--availability">
            </div>
            `;
    }

    return `
        <div data-begin="${data.started_at}" data-end="${
      data.ended_at
    }" class="my-booking booking-item--availability" data-uid="${
      data.uid || ""
    }">
        </div>
        `;
  }

  /**
   *  @description 空き状況を表示する時間帯の高さ単位とstart_timeやend_timeの位置を適当に設定
   */
  displayAvailableBooking() {
    const bookingItems = document.querySelectorAll(".booking-item");
    const availableItems = document.querySelectorAll(
      ".booking-item--availability"
    );

    bookingItems &&
      bookingItems.forEach((el) => {
        el.setAttribute(
          "style",
          `height: ${TIME_UNIT * this.times.length - TIME_UNIT_HAFT}px`
        );
      });

    // 空きの時間帯を表示する
    if (availableItems) {
      availableItems.forEach((availableItem) => {
        const begin = availableItem.dataset.begin;
        const end = availableItem.dataset.end;
        let styleHeight;

        styleHeight =
          (findIndex(this.times15, end) - findIndex(this.times15, begin)) *
          TIME_UNIT_HAFT;

        if (parseInt(begin.split(":")[1]) % 2 !== 0) {
          availableItem.style.borderTop = "none";
        }
        if (parseInt(end.split(":")[1]) % 2 !== 0) {
          availableItem.style.borderBottom = "none";
        }
        const styleTop = findIndex(this.times15, begin) * TIME_UNIT_HAFT;
        availableItem.style.height = `${styleHeight}px`;
        availableItem.style.top = `${styleTop}px`;
      });
    }
  }

  /**
   *
   * @param {
   *      started_at: string
   *      duration: number
   * } reservation
   */
  convertDurationToEnded(reservation) {
    const { started_at, duration } = reservation;
    const startAtSplit = started_at.split(":");

    var hour = parseInt(startAtSplit[0]);
    var minutes = parseInt(startAtSplit[1]);

    const totalMinutes = minutes + duration;
    const period = Math.floor(totalMinutes / 60);

    hour += period;

    if (period > 0) {
      minutes = totalMinutes - 60 * period;
    } else {
      minutes = totalMinutes;
    }
    return `${hour < 10 ? `0${hour}` : `${hour}`}:${
      minutes === 0 ? "00" : `${minutes}`
    }`;
  }

  renderBookingRenderItem(reservations, removeCallback) {
    const box = document.getElementById("js-space-booking-temp-box");
    box.innerHTML = `<p class="Text-Body2 font-bold text-secondary">カレンダーで開始時間枠を選択してください（複数選択可）</p>`;

    let tags = "";

    removeCallback.bind(this);

    reservations.forEach((r, i) => {
      let started_at = new Date(r.started_at + "+09:00");
      let ended_at = new Date(r.ended_at + "+09:00");
      let spaceDay = SpaceDay.convertToObject(started_at);

      tags += `
              <div class="space-booking-temp__item" data-uid="${r.uid}">
                <span class="space-booking-temp__item--date">${
                  spaceDay.month
                }月${spaceDay.date}日(${spaceDay.day})</span>
                <span class="space-booking-temp__item--time">${formatDate(
                  started_at,
                  "HH"
                )}:${formatDate(started_at, "mm")}-${formatDate(
        ended_at,
        "HH"
      )}:${formatDate(ended_at, "mm")}</span>
                <button class="space-booking-temp__item--close" data-uid="${
                  r.uid
                }">
                  <img src="https://dwn6kctd0jdpr.cloudfront.net/img/icon/close.png" alt="remove">
                </button>
              </div>
            `;
    });

    box.innerHTML += tags;

    let buttons = document.querySelectorAll(".space-booking-temp__item--close");
    buttons.forEach(function (el, i) {
      const uid = el.dataset.uid;
      el.addEventListener("click", function () {
        removeCallback(uid);
      });
    });
  }

  removeBookingRenderItem(uid) {
    let items = document.querySelectorAll('[data-uid="' + uid + '"]');
    items.forEach(function (el) {
      el.remove();
    });
  }

  /**
   *
   */
  renderLineOfWrap() {
    const bookingWrap = document.querySelector(".booking-status-box__wrap");
    var mark = "";
    for (var i = 0; i < this.times.length; i++) {
      const line = `
                <div style='top: ${
                  i * TIME_UNIT - 1
                }px' class="booking-box--line">
                </div>
            `;
      mark += line;
    }
    bookingWrap && bookingWrap.insertAdjacentHTML("afterbegin", mark);
  }

  setHeight() {
    var calcHeight =
      window.innerHeight -
      document.getElementsByClassName("calender-box")[0].getBoundingClientRect()
        .top -
      96;
    document.getElementsByClassName(
      "calender-box"
    )[0].style.height = `${calcHeight}px`;
  }

  setScrollPosition() {
    document.getElementsByClassName("calender-box")[0].scrollTop = 490;
  }
}

export default new BookingView();
