document.addEventListener("turbolinks:load", function () {
  const forms = document.querySelectorAll("form#search-form-spaces");

  forms.forEach(function (form) {
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      const spaceTypeSelect = document.querySelector(
        'select[name="space_type"]'
      );
      const areaSelect = document.querySelector('select[name="area"]');
      const spaceType = spaceTypeSelect.value;
      const area = areaSelect.value;
      const url = `/area/${area}?space_type=${spaceType}`;
      window.location.href = url;
    });
  });
});
