/**
 *
 * @param {any} value
 * @returns number: index of arrays
 */
export function findIndex(array, value) {
  return array.indexOf(value);
}

/**
 *
 * @param {*} totalMinutes
 */
export function convertMinutesToDays(totalMinutes) {
  var d = Math.floor(totalMinutes / 1440); // 60*24
  var h = Math.floor((totalMinutes - d * 1440) / 60);
  var m = Math.round(totalMinutes % 60);
  return {
    days: d,
    hours: h,
    minutes: m,
  };
}

/**
 *
 * @param {*} start_at
 * @returns
 */
export function next60Minutes(start_at) {
  const next = parseInt(start_at.split(":")[0]) + 1;
  if (start_at === "23:30") {
    return "24:00";
  }
  return `${next < 10 ? `0${next}` : next}:${start_at.split(":")[1]}`;
}

export function next15Minutes(start_at) {
  var nextHour = parseInt(start_at.split(":")[0]);
  var nextMinute = parseInt(start_at.split(":")[1]) + 15;
  if (nextMinute >= 60) {
    nextHour += 1;
    nextMinute -= 60;
  }

  if (start_at === "23:30") {
    return "24:00";
  }
  return `${nextHour < 10 ? `0${nextHour}` : nextHour}:${
    nextMinute < 10 ? `0${nextMinute}` : nextMinute
  }`;
}

export function next30Minutes(start_at) {
  var nextHour = parseInt(start_at.split(":")[0]);
  var nextMinute = parseInt(start_at.split(":")[1]) + 30;
  if (nextMinute >= 60) {
    nextHour += 1;
    nextMinute -= 60;
  }

  if (start_at === "23:30") {
    return "24:00";
  }
  return `${nextHour < 10 ? `0${nextHour}` : nextHour}:${
    nextMinute < 10 ? `0${nextMinute}` : nextMinute
  }`;
}

export function getUniqueStr(myStrong) {
  var strong = 1000;
  if (myStrong) strong = myStrong;
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  );
}

/**
 *
 * @param {*} time 9:00
 */

// ◯開始時間
// 9:00〜9:14 -> 9:00で表記
// 9:15〜9:29 -> 9:15で表記
// 9:30〜9:44 -> 9:30で表記
// 9:45〜9:59 -> 9:45で表記

export function resolveStartTime(time) {
  let minutes = parseInt(time.split(":")[1]);
  let hour = time.split(":")[0];
  if (parseInt(hour) >= 24 && minutes > 0) {
    hour = "00";
  }
  if (minutes >= 0 && minutes < 15) {
    minutes = "00";
  } else if (minutes >= 15 && minutes < 30) {
    minutes = "15";
  } else if (minutes >= 30 && minutes < 45) {
    minutes = "30";
  } else if (minutes >= 45 && minutes <= 59) {
    minutes = "45";
  } else {
    minutes = time.split(":")[1];
  }
  return `${hour}:${minutes}`;
}

// ◯終了時間
// 9:01〜9:15 -> 9:15で表記
// 9:16〜9:30 -> 9:30で表記
// 9:31〜9:45 -> 9:45で表記
// 9:46〜10:00 -> 10:00で表記
// 24: ~ -> 24:00
export function resolveEndTime(time) {
  let hours = parseInt(time.split(":")[0]);
  let minutes = parseInt(time.split(":")[1]);
  if (hours >= 24 && minutes > 0) {
    hours = 24;
    minutes = "00";
  } else {
    if (minutes > 0 && minutes <= 15) {
      minutes = "15";
    } else if (minutes > 15 && minutes <= 30) {
      minutes = "30";
    } else if (minutes > 30 && minutes <= 45) {
      minutes = "45";
    } else if (minutes > 45 && minutes <= 59) {
      hours += 1;
      minutes = "00";
    } else {
      minutes = time.split(":")[1];
    }
    if (hours >= 24 && minutes > 0) {
      hours = 24;
      minutes = "00";
    }
  }

  return hours < 10 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
}

/**
 *
 * @param {string} str
 * @returns {html}
 */
export function simpleFormat(str) {
  str = $.trim(str);
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, "</p><p>");
    str = str.replace(/\n/g, "<br />");
    str = "<p>" + str + "</p>";
  }
  return str;
}
