import SpaceDay from "../utils/day";
import { convertMinutesToDays } from "../utils/utils";

const initialDate = SpaceDay.today();
let currentDate = initialDate;

class SpaceReservationAvailablity {
  constructor({ info, reservations, startAt, limit }) {
    this.id = info.id;
    this.openTime = info.data;
    this.deadline = info.deadline;
    this.enableBookingInfo = this._calculatorEnableBookingFromNow(
      SpaceDay.today()
    );
    this.reserveDaysAhead = info.reserve_days_ahead - 1; // どこまで先の予定を取得するか。14日、30日、60日。例えば30日の場合は当日を含めないため29になる。
    this.reservations = this._divideReservationAtDays(reservations);
    this.availables = [];
    this.limit = limit;

    this.setAvailableHours(startAt);
    this.setAvailableStatus();
  }

  async updateAvailability(d) {
    const { reservations } = await this.fetchReservation(d);
    this.reservations = this._divideReservationAtDays(reservations);
    this.setAvailableHours(d);
    this.setAvailableStatus();
    return new Promise((resolve) => {
      resolve();
    });
  }

  _formatReservationKeys(reservations) {
    const formattedReservations = {};
    Object.keys(reservations).forEach((key) => {
      const replacedKey = key.replace(/-/g, "/");
      formattedReservations[replacedKey] = reservations[key];
    });

    return formattedReservations;
  }

  // 予約情報が24時以上の場合は最大durationが1440になるように日付別に予約情報の分割を行う処理
  _divideReservationAtDays(rawReservations) {
    const dividedReservation = {};
    const reservations = this._formatReservationKeys(rawReservations);
    Object.keys(reservations).forEach((key) => {
      const reservation = reservations[key];
      // started_atにdurationを足し、24時間を超える場合は分割する
      reservation.forEach((reserve) => {
        let currentDate = new Date(key);
        const nextDate = SpaceDay.nextDate(currentDate);
        const nextDateString = SpaceDay.convertToDate(nextDate, {
          zeroPadding: true,
        });

        const reserveStart = new Date(`${key} ${reserve.started_at}`);
        const reserveStartDayEnd = new Date(`${nextDateString} 00:00`);
        const currentDuration =
          reserveStartDayEnd.getTime() - reserveStart.getTime();
        let remainDuration = reserve.duration * 60000 - currentDuration;

        if (!dividedReservation[key]) {
          dividedReservation[key] = [];
        }
        const duration =
          remainDuration < 0 ? reserve.duration : currentDuration / 60000;

        // currentDate当日内の予約処理
        dividedReservation[key].push({
          started_at: reserve.started_at,
          duration,
        });

        // remainDurationが当日内で処理しきれない場合は、翌日付の予約として分割登録する
        while (remainDuration > 0) {
          const nextDate = SpaceDay.nextDate(currentDate);
          const reserveNextDayEnd = SpaceDay.nextDate(nextDate);
          const nextDateKey = SpaceDay.convertToDate(nextDate, {
            zeroPadding: true,
          });

          const nextDateTime = new Date(`${nextDateKey} 00:00`).getTime();

          if (!dividedReservation[nextDateKey]) {
            dividedReservation[nextDateKey] = [];
          }

          if (nextDateTime + remainDuration > reserveNextDayEnd.getTime()) {
            dividedReservation[nextDateKey].push({
              started_at: "00:00",
              duration: `${
                (reserveNextDayEnd.getTime() - nextDateTime) / 60000
              }`,
            });

            remainDuration =
              nextDateTime + remainDuration - reserveNextDayEnd.getTime();

            currentDate = nextDate;
            continue;
          } else {
            dividedReservation[nextDateKey].push({
              started_at: "00:00",
              duration: `${remainDuration / 60000}`,
            });
            remainDuration = 0;
          }
        }
      });
    });

    return dividedReservation;
  }

  _formattedDate(date, hourMinutesString) {
    const dateTime =
      hourMinutesString === "24:00"
        ? date.getTime() + 24 * 60 * 60000
        : date.getTime();

    if (hourMinutesString === "24:00") {
      return new Date(
        `${SpaceDay.formatDateToString(new Date(dateTime))} 00:00`
      );
    }

    return new Date(
      `${SpaceDay.formatDateToString(new Date(dateTime))} ${hourMinutesString}`
    );
  }

  _getEndDateByDuration(startDate, duration) {
    const endDate = new Date(startDate.getTime() + duration * 60000);
    return endDate;
  }

  _getHourDifference(startDate, endDate) {
    // startDateとendDateの差分時間をhourで返す
    return (endDate.getTime() - startDate.getTime()) / 60000 / 60;
  }

  _isBetweenOpenHours(
    currentDate,
    reservationStartDate,
    reservationEndDate,
    currentOpenTime
  ) {
    // 予約開始時間、終了時間がそれぞれ営業時間内かを判定する
    const dateFrom = this._formattedDate(currentDate, currentOpenTime.startAt);
    const dateTo = this._formattedDate(currentDate, currentOpenTime.endAt);
    return {
      startAt: SpaceDay.betweenDates(reservationStartDate, dateFrom, dateTo),
      endAt: SpaceDay.betweenDates(reservationEndDate, dateFrom, dateTo),
    };
  }

  _isFullyReservation(
    currentDate,
    reservationStartDate,
    reservationEndDate,
    currentOpenTime
  ) {
    // 予約開始時間、終了時間がそれぞれ営業時間外となり実質貸切状態の場合を判定する
    const dateFrom = this._formattedDate(currentDate, currentOpenTime.startAt);
    const dateTo = this._formattedDate(currentDate, currentOpenTime.endAt);
    return (
      SpaceDay.betweenDates(
        dateFrom,
        reservationStartDate,
        reservationEndDate
      ) &&
      SpaceDay.betweenDates(dateTo, reservationStartDate, reservationEndDate)
    );
  }

  // 現在時刻からDeadlineとreserve_days_ahead（締め切り）で予約可能時間を計算する
  _calculatorEnableBookingFromNow(currentDate) {
    const currentTime = {
      day: currentDate.getDay(),
      date: currentDate.getDate(),
      hours: currentDate.getHours(),
      minutes: currentDate.getMinutes(),
    };
    const deadlineTime = convertMinutesToDays(this.deadline);
    let expectDate = currentDate;
    for (let i = 0; i < deadlineTime.days; i++) {
      expectDate = SpaceDay.nextDate(expectDate);
    }
    let expM = currentTime.minutes + deadlineTime.minutes;
    let expH = currentTime.hours + deadlineTime.hours;
    if (expM < 30) {
      expM = "30";
    } else {
      expM = "00";
      expH += 1;
      if (expH > 23) {
        expH = "0";
        expectDate = SpaceDay.nextDate(expectDate);
      }
    }
    expH = expH < 10 ? `0${expH}` : `${expH}`;

    const expectTime = {
      date: expectDate,
      hours: expH,
      minutes: expM,
    };

    return expectTime;
  }

  // 予約済み時間の取得
  _getReservedHour(currentReservation, currentOpenTime) {
    if (!currentReservation) return 0;

    // currentReservationの予約開始start_atが営業時間のstart_atよりも前の場合は、durationから営業開始時間までの差分をマイナスする
    // 例: 営業開始のstart_at: 22:00、予約 { started_at: 20:30, duration: 135 }の場合
    // 22:00 - 20:30 = 1.5h * 60 = 90
    // duration -> 135 - 90 = 45

    let reservedDuration = 0;
    // reduceだと1件のときにループを通らないためforEachにしている
    currentReservation.forEach((current) => {
      const startDate = this._formattedDate(currentDate, current.started_at);
      const endDate = this._getEndDateByDuration(startDate, current.duration);
      const isReservationInOpenHour = this._isBetweenOpenHours(
        currentDate,
        startDate,
        endDate,
        currentOpenTime
      );
      const isFullyReservation = this._isFullyReservation(
        currentDate,
        startDate,
        endDate,
        currentOpenTime
      );

      const sinceOpenHour = this._getHourDifference(
        startDate,
        this._formattedDate(currentDate, currentOpenTime.startAt)
      );
      const sinceCloseHour = this._getHourDifference(
        this._formattedDate(currentDate, currentOpenTime.endAt),
        endDate
      );

      if (isReservationInOpenHour.startAt && isReservationInOpenHour.endAt) {
        // 営業時間内に予約が入り、営業時間内に予約終了する場合はそのまま足す
        reservedDuration = reservedDuration + current.duration;
        return;
      }
      if (!isReservationInOpenHour.startAt && isReservationInOpenHour.endAt) {
        // 営業時間以前に予約が入り、営業時間内に予約終了する場合はsinceOpenHourの時間分を予約時間からマイナスする
        reservedDuration =
          reservedDuration + current.duration - sinceOpenHour * 60;
        return;
      }
      if (isReservationInOpenHour.startAt && !isReservationInOpenHour.endAt) {
        // 営業時間内に予約が入り、営業時間外に予約終了する場合はsinceCloseHourの時間分を予約時間からマイナスする
        reservedDuration =
          reservedDuration + current.duration - sinceCloseHour * 60;
        return;
      }
      if (!isReservationInOpenHour.startAt && !isReservationInOpenHour.endAt) {
        // 営業時間外に予約が入り、営業時間外に予約終了する場合
        if (isFullyReservation) {
          // 営業時間外に予約が開始し、営業時間中はすべて予約されている、かつ営業時間後に予約が終了し実質終日貸切状態の場合
          // 例:
          // 営業時間 10:00-16:00
          // 予約あり 9:00-18:00

          // 指定されたdurationをそのままプラスする
          reservedDuration = reservedDuration + current.duration;
        }

        // 上記条件以外の営業時間外の予約場合は営業時間への影響なしとしてなにもしないでそのまま足す
        // 例:
        // 営業時間 22:00-24:00
        // 予約あり 19:00-20:00
        return;
      }
    });

    // hourで返すのでdurationを60で割る
    return reservedDuration / 60;
  }

  _getfixedCurrentOpenTime(currentDate, currentOpenTime) {
    // 最短予約可能日までの日数を計算する
    const enableReservationDate = new Date(
      `${SpaceDay.convertToDate(this.enableBookingInfo.date)} ${
        this.enableBookingInfo.hours
      }:${this.enableBookingInfo.minutes}`
    );
    const reservableDayCount = SpaceDay.differenceTwoDates(
      enableReservationDate,
      currentDate
    );
    // 当日が予約可能日ではない場合は、当日の予約枠は0とする
    if (reservableDayCount < 0) {
      return {
        startAt: "00:00",
        endAt: "00:00",
      };
    }
    // 予約可能日の翌日以降は時間帯情報を操作せずそのまま返す
    if (reservableDayCount > 0) {
      return currentOpenTime;
    }

    // 以下予約処理可能かチェック
    const fixedFormattedTime = `${this.enableBookingInfo.hours}:${this.enableBookingInfo.minutes}`;
    // 予約締め切り時間を加味して補正済みの現時刻のtime（この時刻では、既に予約が埋まっているかは考慮していない）
    const currentTime = this._formattedDate(
      currentDate,
      fixedFormattedTime
    ).getTime();
    // 現在の予約可能時刻のtimeを取得する
    const openStartTime = this._formattedDate(
      currentDate,
      currentOpenTime.startAt
    ).getTime();
    // 既存予約がある場合はその時間分オフセットした空き時間を取得する
    const openTimeOffset =
      currentTime > openStartTime ? currentTime - openStartTime : 0;
    if (openTimeOffset > 0) {
      const offsetDate = new Date(openStartTime + openTimeOffset);

      return {
        startAt: `${offsetDate.getHours()}:${offsetDate.getMinutes()}`,
        endAt: currentOpenTime.endAt,
      };
    }

    return {
      startAt: currentOpenTime.startAt,
      endAt: currentOpenTime.endAt,
    };
  }

  _setAvailableDateHours(currentDate) {
    const dayNumber = currentDate.getDay();
    const currentOpenTime = {
      startAt: this.openTime[dayNumber].start_at,
      endAt: this.openTime[dayNumber].end_at,
    };
    const currentDateString = SpaceDay.convertToDate(currentDate, {
      zeroPadding: true,
    });
    const currentReservation = this.reservations[currentDateString];

    // 予約締め切り時間を基準として当日の利用可能時間を計算
    const fixedCurrentOpenTime = this._getfixedCurrentOpenTime(
      currentDate,
      currentOpenTime
    );

    // 予約締め切り時間を加味した利用可能時間
    const openHour = this._getHourDifference(
      this._formattedDate(currentDate, fixedCurrentOpenTime.startAt),
      this._formattedDate(currentDate, fixedCurrentOpenTime.endAt)
    );

    // 予約済み時間
    const reservedHour = this._getReservedHour(
      currentReservation,
      fixedCurrentOpenTime
    );

    // 予約利用可能時間
    const availableHour =
      openHour >= reservedHour ? openHour - reservedHour : 0;
    this.availables.push({ date: currentDateString, hour: availableHour });
  }

  setAvailableHours(d) {
    let currentDay = d;
    this.availables = [];
    this._setAvailableDateHours(currentDay);

    for (let i = 1; i < this.limit; i++) {
      currentDay = SpaceDay.nextDate(currentDay);
      this._setAvailableDateHours(currentDay);
    }
  }

  setAvailableStatus() {
    this.availables.forEach((available) => {
      const isOverReserveLimit =
        SpaceDay.differenceTwoDates(initialDate, new Date(available.date)) >
        this.reserveDaysAhead;

      if (isOverReserveLimit) {
        // 予約可能日以降のスケジュールは全てnot-openにする
        available.symbol = "not-open";
        return;
      }
      if (available.hour >= 7) {
        available.symbol = "open";
      } else if (available.hour >= 3) {
        available.symbol = "average";
      } else if (available.hour < 3 && available.hour > 0) {
        available.symbol = "few";
      } else {
        available.symbol = "not-open";
      }
    });
  }

  fetchReservation(d) {
    const currentDay = SpaceDay.convertToDate(new Date(d), { delimiter: "-" });
    return $.ajax(
      `/api/reservations.json?id=${this.id}&date=${currentDay}&week_num=2`
    );
  }
}

const initializeSpaceSchedule = ($spaceItem, startAt, limit) => {
  const $schedule = $($spaceItem).find(".rentalspace-item__schedule");
  const info = $($schedule).length > 0 ? $($schedule).data("info") : {};
  const reservations =
    $($schedule).length > 0 ? $($schedule).data("reservations") : {};
  const space = new SpaceReservationAvailablity({
    info,
    reservations,
    startAt,
    limit,
  });

  return space;
};

const bindSpaceSchedule = ({ $selector, instance }) => {
  const $statuses = $($selector).find(
    ".rentalspace-item__schedule .rentalspace-item__schedule--status"
  );
  const $date = $($selector).find(
    ".rentalspace-item__schedule .rentalspace-item__schedule--title"
  );
  for (let j = 0; j < $statuses.length; j++) {
    // 日付の更新
    const date = SpaceDay.convertToObject(
      new Date(instance.availables[j].date)
    );
    $($date[j]).find(".rentalspace-item__schedule--day").text(date.day);
    // 毎月1日の場合は月を表示する
    if (date.date == 1) {
      $($date[j])
        .find(".rentalspace-item__schedule--month")
        .text(`${date.month}/`);
    } else if (j == 0) {
      $($date[j])
        .find(".rentalspace-item__schedule--month")
        .text(`${date.month}/`);
    } else if (j == 7) {
      $($date[j])
        .find(".rentalspace-item__schedule--month")
        .text(`${date.month}/`);
    } else {
      $($date[j]).find(".rentalspace-item__schedule--month").text("");
    }

    $($date[j]).find(".rentalspace-item__schedule--date").text(date.date);

    // アイコンの更新
    $($statuses[j]).find(".symbol").removeClass("active");
    $($statuses[j])
      .find(`.${instance.availables[j].symbol}`)
      .addClass("active");
  }
};

const setDisableSlider = (direction, $selector) => {
  $($selector)
    .find(".rentalspace-item__schedule--wrapper")
    .find(".schedule-sliders-arrow--left")
    .removeClass("disabled");
  $($selector)
    .find(".rentalspace-item__schedule--wrapper")
    .find(".schedule-sliders-arrow--right")
    .removeClass("disabled");

  if (!direction) return;

  $($selector)
    .find(".rentalspace-item__schedule--wrapper")
    .find(`.schedule-sliders-arrow--${direction}`)
    .addClass("disabled");
};

const isDisalbedSlider = ($selector) => {
  return $($selector).hasClass("disabled");
};

// NOTE:
// 詳細ページの処理と一覧ページの処理はほぼ同じ
// 詳細ページは14日分、一覧ページは7日分のスケジュールを表示する
// 詳細ページのnextWeek関数の引数は2、一覧ページは1
// 共通化できるが気が向いたらやる
window.document.addEventListener("turbolinks:load", (event) => {
  // スペース詳細ページの処理
  const $spaceDetail = $(".js-spaceDetail.spacedetail-wrapper");
  if ($spaceDetail.length > 0) {
    const initialDate = SpaceDay.today();
    let currentDate = initialDate;

    let space = initializeSpaceSchedule($spaceDetail, currentDate, 14);
    bindSpaceSchedule({ $selector: $spaceDetail, instance: space });

    const updateScheduleView = async (date) => {
      await space.updateAvailability(date);
      bindSpaceSchedule({ $selector: $spaceDetail, instance: space });
    };

    // sliderの矢印制御判定
    setDisableSlider("left", $spaceDetail); // 初期状態ではprevious方向にはクリック不可

    const $schedule = $($spaceDetail)
      .find(".rentalspace-item__schedule--wrapper")
      .find(".sliders-arrow");

    //previous event bind
    $($schedule)
      .filter(".schedule-sliders-arrow--left")
      .on("click", (ev) => {
        if (isDisalbedSlider(ev.currentTarget)) return;

        currentDate = SpaceDay.previousWeek(currentDate, 2);
        updateScheduleView(currentDate);

        if (SpaceDay.differenceTwoDates(initialDate, currentDate) === 0) {
          setDisableSlider("left", $spaceDetail);
        } else {
          setDisableSlider("", $spaceDetail);
        }
      });

    // next event bind
    $($schedule)
      .filter(".schedule-sliders-arrow--right")
      .on("click", (ev) => {
        if (isDisalbedSlider(ev.currentTarget)) return;

        currentDate = SpaceDay.nextWeek(currentDate, 2);
        updateScheduleView(currentDate);

        const nextTarget = SpaceDay.nextWeek(currentDate, 2);
        if (
          SpaceDay.differenceTwoDates(initialDate, nextTarget) >
          space.reserveDaysAhead
        ) {
          setDisableSlider("right", $spaceDetail);
        } else {
          setDisableSlider("", $spaceDetail);
        }
      });

    return;
  }
});

window.document.addEventListener("turbolinks:load", (event) => {
  // スペース一覧ページの処理
  const initialDate = SpaceDay.today();

  const $spaceItem = $(".js-spaceDetail.spacedetail-wrapper-list");
  for (let i = 0; i < $spaceItem.length; i++) {
    let $spaceDetail = $spaceItem[i];
    let currentDate = initialDate;

    const space = initializeSpaceSchedule($spaceDetail, initialDate, 7);
    bindSpaceSchedule({ $selector: $spaceDetail, instance: space });
    const updateScheduleView = async (date) => {
      await space.updateAvailability(date);
      bindSpaceSchedule({ $selector: $spaceDetail, instance: space });
    };

    // sliderの矢印制御判定
    setDisableSlider("left", $spaceDetail); // 初期状態ではprevious方向にはクリック不可

    const $schedule = $($spaceDetail)
      .find(".rentalspace-item__schedule--wrapper")
      .find(".sliders-arrow");

    //previous event bind
    $($schedule)
      .filter(".schedule-sliders-arrow--left")
      .on("click", (ev) => {
        if (isDisalbedSlider(ev.currentTarget)) return;

        currentDate = SpaceDay.previousWeek(currentDate, 1);
        updateScheduleView(currentDate);

        if (SpaceDay.differenceTwoDates(initialDate, currentDate) === 0) {
          setDisableSlider("left", $spaceDetail);
        } else {
          setDisableSlider("", $spaceDetail);
        }
      });

    // next event bind
    $($schedule)
      .filter(".schedule-sliders-arrow--right")
      .on("click", (ev) => {
        if (isDisalbedSlider(ev.currentTarget)) return;

        currentDate = SpaceDay.nextWeek(currentDate, 1);
        updateScheduleView(currentDate);

        const nextTarget = SpaceDay.nextWeek(currentDate, 1);
        if (
          SpaceDay.differenceTwoDates(initialDate, nextTarget) >
          space.reserveDaysAhead
        ) {
          setDisableSlider("right", $spaceDetail);
        } else {
          setDisableSlider("", $spaceDetail);
        }
      });
  }
});
