import SpaceDay from "../utils/day";
import FixedBody from "../utils/fixedBody";
import { formatDate } from "../utils/format_date";
import {
  convertMinutesToDays,
  getUniqueStr,
  resolveEndTime,
  resolveStartTime,
} from "../utils/utils";
import BookingService from "./bookingService";
import BookingView from "./bookingView";
import ModalView from "./modalView";

class BookingController {
  constructor() {
    this.currentDatesOfWeek = [];
    this.isFirstBooked = 0;
    this.reservations = [];
    this.reservationMock = {
      reservations: {},
    };
  }

  /**
   *
   * @param {*} spaceId
   * @param {*} data
   * @param {*} startDate
   */
  showDataIntoGraph(spaceId, data, startDate) {
    const enableBooking = this.calculatorEnableBookingFromNow();

    const startDay = startDate.getDay();
    const max = 6;
    var markup = "";

    var currentDatesOfWeek = [];

    if (startDay === 0) {
      // from 0 -> 6
      for (var i = 0; i <= 6; i++) {
        markup += this.renderSpaceAvailable(enableBooking, startDate, data[i]);

        currentDatesOfWeek.push(startDate);
        startDate = SpaceDay.nextDate(startDate);
      }
    } else {
      // from start -> max
      var tempStartDate = startDate;
      for (var i = startDay; i <= max; i++) {
        markup += this.renderSpaceAvailable(
          enableBooking,
          tempStartDate,
          data[i]
        );

        currentDatesOfWeek.push(tempStartDate);
        tempStartDate = SpaceDay.nextDate(tempStartDate);
      }

      // next start day
      var nextStart = max - startDay + 1;
      for (var i = 0; i < nextStart; i++) {
        startDate = SpaceDay.nextDate(startDate);
      }
      // from 0 -> (start - 1)
      for (var i = 0; i < startDay; i++) {
        markup += this.renderSpaceAvailable(enableBooking, startDate, data[i]);

        currentDatesOfWeek.push(startDate);
        startDate = SpaceDay.nextDate(startDate);
      }
    }

    BookingView.applyAvailableBookingMarkup(markup);
    BookingView.displayAvailableBooking();

    this.currentDatesOfWeek = currentDatesOfWeek;

    // 予約jsonを取得
    BookingService.getReservations(spaceId, this.currentDatesOfWeek[0])
      .then((data) => {
        this.reservation = data;
        this.prepareReservations();

        // マイ予約を表示する
        for (var d of currentDatesOfWeek) {
          this.showMyBooking(d, this.reservation);
          this.showMyBooking(d, this.reservationMock);
        }

        const loader = document.querySelector(".js-space-booking-loading");
        loader.classList.add("hidden");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * @description 予約データからグラフに表示する
   * @param {*} d Date
   */
  showMyBooking(d, reservation) {
    const reservationsKeys = Object.keys(reservation.reservations);
    for (var date of reservationsKeys) {
      if (SpaceDay.compareTwoDates(d, new Date(date))) {
        const bookingTimeWrapEl = document.querySelector(`[data-date="${d}"]`);

        for (var timeItem of reservation.reservations[date]) {
          const html = BookingView.renderMyBooking(timeItem);
          bookingTimeWrapEl.insertAdjacentHTML("beforeend", html);
          this.displayAvailableBooking();
        }
      }
    }
  }

  /**
   * @description startDateを起点に一週間の日付を表示する
   * @param {*} startDate Date
   */
  showCalendar(startDate) {
    this.showTimeLine();

    const spaceId = document.querySelector(".js-available-wrapper").dataset
      .spaceId;
    var mark = `
        <div class="day-item">
            日時
        </div>
        `;

    var startDateObj = SpaceDay.convertToObject(startDate);

    for (var i = 0; i < 7; i++) {
      // 一週間の日付を作成する
      mark += BookingView.renderDaysOfWeek(startDateObj);

      // 次の日に変更
      startDate = SpaceDay.nextDate(startDate);
      startDateObj = SpaceDay.convertToObject(startDate);
    }
    BookingService.getSpaceJson(spaceId).then((data) => {
      this.spaceData = data;
      this.showDataIntoGraph(
        spaceId,
        this.spaceData.data,
        SpaceDay.previousWeek(startDate)
      );
      this.ReservationModelProcess();
    });

    BookingView.applyDayOfWeekMarkup(mark);

    BookingView.setHeight();
    BookingView.setScrollPosition();

    window.addEventListener("resize", function () {
      BookingView.setHeight();
      BookingView.setScrollPosition();
    });
  }

  /**
   * @description 00:00から24:00までタイムを表示する
   */
  showTimeLine() {
    BookingView.renderTimeLine();
  }

  /**
   * @description 空き状況を表示する時間帯の高さ単位とstart_timeやend_timeの位置を適当に設定
   */
  displayAvailableBooking() {
    BookingView.displayAvailableBooking();
  }

  /**
   * @description 予約モダールの処理
   */
  ReservationModelProcess() {
    // 空き状況の時間帯をクリックすると予約モーダルを表示する
    const bookingItems = document.querySelectorAll(".booking-item");
    const bookingModal = document.querySelector(".space-booking-modal");

    const submitBtn = document.getElementById("modal-booking-submit");

    bookingItems.forEach((bookingItem) => {
      bookingItem.addEventListener("click", displayModal.bind(this));
    });

    if (bookingModal) {
      bookingModal.addEventListener("click", this.closeModal);
    }

    // 予約ボタンを活性化
    submitBtn.disabled = false;
    // 確定ボタンをクリック
    submitBtn.onclick = this.bookingFormController.bind(this);

    function displayModal(event) {
      const bookingItem = event.target.closest(".booking-item");
      const availableBooking = event.target.closest(
        ".booking-item--availability"
      );
      const notAvailableBooking = event.target.closest(
        ".none.booking-item--availability"
      );
      const myBooking = event.target.closest(
        ".my-booking.booking-item--availability"
      );
      const selectedBooking = event.target.closest(
        ".selected-booking.booking-item--availability"
      );
      const first_visit = document.querySelector(".js-available-wrapper")
        .dataset.firstVisit;

      if (
        myBooking ||
        !availableBooking ||
        notAvailableBooking ||
        selectedBooking
      )
        return;

      if (first_visit === "true" && this.reservations.length > 0) return;

      if (this.reservations.length > 7) {
        alert(
          "同時に行える予約は8つまでです。予約を完了して再度追加の予約を行ってください。"
        );
        return;
      }

      // 予約モダールを表示する
      this.openModal();

      const selectedDate = new Date(bookingItem.dataset.date);

      var selectedStart,
        selectedEnd = "";

      if (availableBooking) {
        selectedStart = availableBooking.dataset.begin;
        selectedEnd = availableBooking.dataset.end;
      }
      // 選択された日付をタイムを表示する
      ModalView.renderTime(selectedStart, selectedEnd, selectedDate);
    }
  }

  closeModal() {
    const bookingModal = document.querySelector(".space-booking-modal");
    const bookingForm = document.querySelector(".space-booking-form-wrapper");

    bookingModal.classList.remove("open");
    bookingForm.classList.remove("open");
    FixedBody.unFixedBody();
  }

  openModal() {
    const bookingModal = document.querySelector(".space-booking-modal");
    const bookingForm = document.querySelector(".space-booking-form-wrapper");

    ModalView.displayError(null);
    FixedBody.fixedBody();
    bookingModal.classList.add("open");
    bookingForm.classList.add("open");
  }

  /**
   * @description 予約モダールの値を取得
   */
  getBookingModalValue() {
    const selectedDate = document.getElementById("selectedDate").value;
    const selectedStart = document.getElementById("space-time-begin").value;
    const selectedEnd = document.getElementById("space-time-end").value;

    return {
      date: selectedDate,
      started_at: selectedStart,
      ended_at: selectedEnd,
    };
  }

  /**
   * @description 予約モダールの確定ボタンを押す時のハンドルメソッド
   */
  bookingFormController() {
    const newReservation = this.getBookingModalValue();

    // 予約をチェック
    const spaceId = this.spaceData.id;
    const startedAtString = `${formatDate(
      new Date(newReservation.date),
      "yyyy-MM-dd"
    )}T${newReservation.started_at}:00+09:00`;
    const endedAtString = `${formatDate(
      new Date(newReservation.date),
      "yyyy-MM-dd"
    )}T${newReservation.ended_at}:00+09:00`;
    const started_at = formatDate(
      new Date(startedAtString),
      "yyyy-MM-ddTHH:mm"
    );
    const ended_at = formatDate(new Date(endedAtString), "yyyy-MM-ddTHH:mm");

    // 予約重複クリック制御
    const submitBtn = document.getElementById("modal-booking-submit");
    submitBtn.disabled = true;

    BookingService.checkReserveAble(spaceId, started_at, ended_at).then(
      (data) => {
        var { message, result, space_id, name } = data;
        ModalView.displayError(message);
        if (result === "OK") {
          const uid = getUniqueStr();

          // Post Request Jsonを生成
          this.generateReservationsRequest(
            spaceId,
            started_at,
            ended_at,
            name,
            uid
          );

          // render Hidden field

          ModalView.renderHiddenFields(this.reservations);

          // 上部アイテム表示
          BookingView.renderBookingRenderItem(
            this.reservations,
            this.removeCallback.bind(this)
          );

          // 確定された予約を画面描画
          this.renderNewReservation(newReservation, uid);

          // モダールを閉じる
          this.closeModal();

          var go_to_reserve_btn = document.getElementById("go-to-reserve");
          if (this.reservations.length > 0) {
            go_to_reserve_btn.disabled = false;
          } else {
            go_to_reserve_btn.disabled = true;
          }
        }
        // 予約ボタンを活性化
        submitBtn.disabled = false;
      }
    );
  }

  /**
   *
   * @param {*} newReservation
   * @param {*} uid
   */
  renderNewReservation(newReservation, uid) {
    // 選択された予約のデータを作成
    const currentBookingDate = formatDate(
      new Date(newReservation.date),
      "yyyy-MM-dd"
    );

    newReservation[currentBookingDate] = {
      started_at: newReservation.started_at,
      duration: this.convertEndedToDuration(newReservation),
      uid: uid,
    };

    if (!this.reservationMock.reservations[currentBookingDate]) {
      this.reservationMock.reservations[currentBookingDate] = [];
    }

    this.reservationMock.reservations[currentBookingDate].push(
      newReservation[currentBookingDate]
    );

    // 画面に表示
    for (var d of this.currentDatesOfWeek) {
      this.showMyBooking(d, this.reservationMock);
    }
  }

  /**
   *
   * @param {*} id
   * @param {*} started_at
   * @param {*} ended_at
   * @param {*} uid
   */
  generateReservationsRequest(id, started_at, ended_at, name, uid) {
    const reservation = {
      id,
      started_at,
      ended_at,
      name,
      uid,
    };
    this.reservations.push(reservation);
  }

  removeCallback(uid) {
    // viewを削除
    BookingView.removeBookingRenderItem(uid);

    this.reservations = this.reservations.filter(function (v) {
      return v.uid != uid;
    });
    ModalView.renderHiddenFields(this.reservations);

    var newMockReservations = {};
    var mockReservations = this.reservationMock.reservations;

    Object.keys(mockReservations).forEach(function (key) {
      var arry = mockReservations[key].filter(function (v) {
        return v.uid != uid;
      });
      newMockReservations[key] = arry;
    });
    this.reservationMock.reservations = newMockReservations;

    var go_to_reserve_btn = document.getElementById("go-to-reserve");
    if (this.reservations.length > 0) {
      go_to_reserve_btn.disabled = false;
    } else {
      go_to_reserve_btn.disabled = true;
    }
  }

  /**
   *
   * @param { * } reservation
   */
  convertEndedToDuration(reservation) {
    const { started_at, ended_at } = reservation;
    const startAtSplit = started_at.split(":");
    const endAtSplit = ended_at.split(":");

    var StarHour = parseInt(startAtSplit[0]);
    var startMinutes = parseInt(startAtSplit[1]);

    var EndHour = parseInt(endAtSplit[0]);
    var EndMinutes = parseInt(endAtSplit[1]);

    const periodH = EndHour - StarHour;
    const periodM = EndMinutes - startMinutes;

    return periodH * 60 + periodM;
  }

  /**
   * @description このファンクションは現在時刻からDeadlineとreserve_days_ahead（締め切り）で予約可能時間を計算する
   * @param {
   *  start_at: string
   *  end_at: string
   * } times
   */
  calculatorEnableBookingFromNow(times) {
    const currentTime = {
      day: SpaceDay.now.getDay(),
      date: SpaceDay.now.getDate(),
      hours: SpaceDay.now.getHours(),
      minutes: SpaceDay.now.getMinutes(),
    };
    const { deadline, reserve_days_ahead } = this.spaceData;
    var nextHead = new Date(SpaceDay.now);
    nextHead.setDate(nextHead.getDate() + parseInt(reserve_days_ahead));
    const deadlineTime = convertMinutesToDays(deadline);
    var expectDate = SpaceDay.now;
    for (var i = 0; i < deadlineTime.days; i++) {
      expectDate = SpaceDay.nextDate(expectDate);
    }
    var expM = currentTime.minutes + deadlineTime.minutes;
    var expH = currentTime.hours + deadlineTime.hours;

    if (expM >= 60) {
      expH += Math.floor(expM / 60);
      expM = expM % 60;
    }

    expM = Math.ceil(expM / 15) * 15;
    if (expM === 60) {
      expH += 1;
      expM = 0;
    }

    if (expH >= 24) {
      expectDate.setDate(expectDate.getDate() + 1);
      expH = expH % 24;
    }

    const expectTime = {
      day: currentTime.day + deadlineTime.days,
      date: expectDate,
      hours: expH,
      minutes: expM,
      nextHead,
    };

    return expectTime;
  }

  /**
   *
   * @param {sssss} enableBooking
   * @param {*} currentDate
   * @param {*} data
   */
  renderSpaceAvailable(enableBooking, currentDate, data) {
    data.start_at = resolveStartTime(data.start_at);
    data.end_at = resolveEndTime(data.end_at);
    const emptyData = { start_at: "00:00", end_at: "00:00" };

    var markup = "";
    if (
      new Date(enableBooking.date).getTime() >
        new Date(currentDate).getTime() ||
      new Date(enableBooking.nextHead).getTime() <=
        new Date(currentDate).getTime()
    ) {
      markup += BookingView.renderBookingAvailable(emptyData, currentDate);
    } else {
      if (
        SpaceDay.compareTwoDates(
          new Date(enableBooking.date),
          new Date(currentDate)
        )
      ) {
        const startHours = parseInt(data.start_at.split(":")[0]);

        if (enableBooking.hours < startHours) {
          markup += BookingView.renderBookingAvailable(data, currentDate);
        } else {
          const newStart_at = `${
            enableBooking.hours < 10
              ? `0${enableBooking.hours}`
              : enableBooking.hours
          }:${
            enableBooking.minutes === 0
              ? `0${enableBooking.minutes}`
              : enableBooking.minutes
          }`;
          data.start_at = newStart_at;
          markup += BookingView.renderBookingAvailable(data, currentDate);
        }
      } else {
        markup += BookingView.renderBookingAvailable(data, currentDate);
      }
    }
    return markup;
  }

  renderLine() {
    BookingView.renderLineOfWrap();
  }

  /**
   *
   */
  prepareReservations() {
    const reservations = this.reservation.reservations;
    let addedReservations = {};
    Object.keys(reservations).map((date) => {
      Array.from(reservations[date]).forEach((reservation) => {
        if (reservation.duration > 1440) {
          addedReservations = Object.assign(
            addedReservations,
            this.calculatorDuration(date, reservation)
          );
          reservation.duration = 1440;
        }
      });
    });
    this.reservation.reservations = Object.assign(
      this.reservation.reservations,
      addedReservations
    );
  }

  /**
   *
   * @param {*} date 2021-09-02: string
   */
  checkReservationIsExist(date) {
    return Object.keys(this.reservation.reservations).find(
      (key) => key === date
    );
  }

  /**
   *
   * @param {*} date // 2021-09-02: string
   * @param {*} reservation // {"started_at":"00:00","duration":1440}
   */
  calculatorDuration(date, reservation) {
    const oneDayM = 1440;
    const addedReservations = {};
    const { days, hours, minutes } = convertMinutesToDays(reservation.duration);
    let currentDate = new Date(date);

    for (let i = 0; i < days - 1; i++) {
      const nextDate = SpaceDay.nextDate(currentDate);
      currentDate = nextDate;
      const nextDateStr = formatDate(nextDate, "yyyy-MM-dd");
      if (this.checkReservationIsExist(nextDateStr)) {
        this.reservation.reservations[nextDateStr].push({
          started_at: "00:00",
          duration: oneDayM,
        });
      } else {
        addedReservations[nextDateStr] = [
          {
            started_at: "00:00",
            duration: oneDayM,
          },
        ];
      }
    }

    if (hours * 60 + minutes > 0) {
      const nextNextDateStr = formatDate(
        SpaceDay.nextDate(currentDate),
        "yyyy-MM-dd"
      );
      if (this.checkReservationIsExist(nextNextDateStr)) {
        this.reservation.reservations[nextNextDateStr].push({
          started_at: "00:00",
          duration: hours * 60 + minutes,
        });
      } else {
        addedReservations[nextNextDateStr] = [
          {
            started_at: "00:00",
            duration: hours * 60 + minutes,
          },
        ];
      }
    }

    return addedReservations;
  }
}

export default new BookingController();
